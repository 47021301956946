import React, { Component } from "react"
import { Link } from 'gatsby'
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu
} from 'reactstrap';
import CustomDropdownMenu from './dropDownMenu'

class Navigation extends Component {

    state = {
        isOpen: false
    }

    toggle = (e) => {
        if (e.nativeEvent) {
            if (e.nativeEvent.type !== 'click' && this.state.isOpen) return;
        }
        else {
            if (e.type !== 'click' && this.state.isOpen) return;
        }
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <Dropdown nav inNavbar key={"dropdown_" + this.props.index} isOpen={this.state.isOpen} toggle={this.toggle} className={this.props.className}>
                <DropdownToggle nav caret>
                    {this.props.links.name}
                </DropdownToggle>
                <DropdownMenu left="true">
                    {
                        this.props.links.links.map((link, index) => {
                            if (link.links && link.links.length > 0) {
                                return (
                                    <CustomDropdownMenu links={link} index={index} className='subSubMenu' toggleParent={this.toggle} collapse={this.props.collapse} />
                                )
                            } else {
                                if (this.props.className === 'subSubMenu') {
                                    return (
                                        <Link key={"sub_" + index} to={link.link} activeClassName="active" onClick={(e) => { this.props.toggleParent(e); this.props.collapse() }}>
                                            {link.name}
                                        </Link>
                                    )
                                }
                                else {
                                    if (link.type === 'external') {
                                        return (
                                            <a href={link.link} target="_blank" rel="noopener noreferrer" alt={link.name}>{link.name}</a>
                                        )
                                    }
                                    return (
                                        <Link key={"sub_" + index} to={link.link} activeClassName="active" onClick={this.toggle}>
                                            {link.name}
                                        </Link>
                                    )
                                }
                            }
                        })
                    }
                </DropdownMenu>
            </Dropdown>
        )
    }
}

export default Navigation
