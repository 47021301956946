import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'

import Header from './header/header'
import Footer from './footer'

import "animate.css/animate.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/layout.css'

class Layout extends Component {
  state = {
    className: ""
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (event) => {
    if (window.scrollY > 400) {
      if (this.state.className != 'show') {
        this.setState({
          className: "show"
        })
      }
    }
    else {
      if (this.state.className != '') {
        this.setState({
          className: ""
        })
      }
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        },
        fileName: file(relativePath: { eq: "logo2.png" }) {
          childImageSharp {
            fixed(width: 190) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
            >
              <html lang="de" />
              <meta name="author" content="LEHMANN+PARTNER Informatik AG" />
              <meta name="company" content=" LEHMANN+PARTNER Informatik AG" />
              <meta name="description" content="ABACUS HR, Lohn, Zeit und Payroll " />
              <meta name="keywords" content="abacus, lohn, payroll, zeit, absenzen, mitarbeiterbeurteilung, mitarbeitergespräche, performance management, arbeitszeugnis, personalakte, personaldossier, HR Portal, Mitarbeiterportal, absenzen, organisation, stellenplan, salärbudget, salärrunde, vergütungsmanagement, rekrutierung, bewerber, talenmanagement, ess app, abaclik, schweiz" />


              <script async src="https://www.googletagmanager.com/gtag/js?id=G-ZDZG50C1GZ"></script>
              
              <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                
                gtag('js', new Date());
                gtag('config', 'G-ZDZG50C1GZ');

              `}
              </script>
              


            </Helmet>
            <Header logo={data.fileName.childImageSharp.fixed} />
            <div id="content">
              {this.props.children}

              <div className={"back-To-Top " + this.state.className} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} >
                <FontAwesomeIcon icon={faChevronUp} />
              </div>
            </div>
            <Footer />
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
