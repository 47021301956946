export const pages = [
    {
        link: '/',
        name: 'Home'
    },
    {
        name: 'Unternehmen',
        links: [
            {
                link: '/about',
                name: 'Über uns'
            },
            {
                link: '/obt-gruppe',
                name: 'OBT Gruppe'
            },
            {
                link: '/news',
                name: 'News'
            },
            {
                link: '/jobs',
                name: 'Jobs'
            },
            {
                link: '/downloads',
                name: 'Downloads'
            }
        ]
    },
    {
        name: 'Produkte',
        links: [
            {
                link: '/products/abacusHR',
                name: 'Abacus HR'
            },
            {
                link: '/products/payroll',
                name: 'Abacus Lohnbuchhaltung'
            },
            {
                link: '/products/time',
                name: 'Abacus Zeiterfassung'
            },
            {
                link: '/products/org',
                name: 'Abacus Organisation'
            },
            {
                link: '/products/hrportal',
                name: 'Abacus HR Portal'
            },
            {
                link: '/products/hrmobil',
                name: 'Abacus HR Mobile'
            },
            {
                link: '/products/hrprozesse',
                name: 'Abacus HR Prozesse'
            },
            {
                link: '/products/hrreporting',
                name: 'Abacus HR Reporting'
            },
            {
                link: '/products/datenmigrationen',
                name: 'Datenmigrationen'
            },
            {
                link: 'https://www.zeugnisprozesse.ch/',
                name: 'ZEP - Zeugnisprozess',
                type: 'external'
            },
            {
                link: 'https://www.certicat.ch/',
                name: 'Certicat - Online Arbeitszeugnis',
                type: 'external'
            },
            {
                link: '/products/xpert',
                name: 'Xpert.HRM'
            }
        ]
    },
    {
        link: '/services',
        name: 'Dienstleistungen'
    },
    {
        link: '/customers',
        name: 'Referenzen'
    },
    {
        link: '/kontakt',
        name: 'Kontakt'
    }
]