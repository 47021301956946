import React from 'react'
import { Link } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'


import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import Logo from '../images/logo2.png'
import LogoOBT from '../images/OBT_weiss.png'
import youtube from '../images/youtube.png'
import linkedin from '../images/linkedin.png'

import './styles/footer.css'

class Footer extends React.Component {

    state = {
        modal: false
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {

        return (

            <footer id="footer">

                <Modal isOpen={this.state.modal} fade={false} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Impressum</ModalHeader>
                    <ModalBody>
                        <p>Verantwortlich für den Inhalt der Seiten:</p>
                        <p>
                            LEHMANN+PARTNER Informatik AG
                            <br /> Pilatusstrasse 39
                            <br /> 6003 Luzern 
                        </p>
                        <p>
                            <a href="tel:+414122730 70">+41 41 227 30 70</a>
                            <br /> <a href="mailto:info@lepa.ch">info@lepa.ch</a>
                        </p>
                    </ModalBody>
                </Modal>

                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-8 footer-info">
                                {/*<h3>LEHMANN+PARTNER<br />Informatik AG</h3>
                                <p>Wir machen aus Daten Informationen</p>
                            */}
                                <img src={Logo} alt="logo" width="300px" />
                                <br /><br />
                                <a href="https://obt.ch" target="_blank"> 
                                    <img src={LogoOBT} alt="logo" width="140px" />
                                    <br />
                                    <div class="small text-white obtText">Ein Unternehmen der OBT Gruppe</div> 
                                </a>

                            </div>
                            <div className="col-lg-4 col-md-6 footer-links">
                                <h4>Nützliche Links</h4>
                                <ul>
                                    <li><FontAwesomeIcon icon={faChevronRight} /> <Link to="/">Home</Link></li>
                                    <li><FontAwesomeIcon icon={faChevronRight} /> <a href="https://islonline.obt.ch/start/ISLLightClient" rel="noopener noreferrer" target="_blank">Support</a></li>
                                    <li><FontAwesomeIcon icon={faChevronRight} /> <span onClick={this.toggle}>Impressum</span></li>
                                    <li><FontAwesomeIcon icon={faChevronRight} /> <Link to="/datenschutzerklaerung">DSE</Link></li>
                                    <li><FontAwesomeIcon icon={faChevronRight} /> <Link to="/agb">AGB</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 footer-contact">
                                <h4>Kontakt</h4>
                                <p>LEHMANN+PARTNER Informatik AG
                                <br /> Pilatusstrasse 39
                                <br /> 6003 Luzern
                                <br /> <a href="tel:+41412273070">+41 41 227 30 70</a>
                                    <br /> <a href="mailto:info@lepa.ch">info@lepa.ch</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    © 2023 – LEHMANN+PARTNER Informatik AG
                    <a href="https://www.youtube.com/channel/UCVVdWP90nor114fiUDQe4oA" className="social" target="_blank" rel="noopener noreferrer"><img src={youtube} alt="youtube" height="14px" /></a>
                    <a href="https://www.linkedin.com/company/lehmannpartner" className="social" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="linkedin" height="14px" /></a>
                </div>
            </footer>
        )
    }
}

export default Footer
